<template>
  <div>
    <div>
      <pageBuilder />
    </div>
  </div>
</template>

<script>
export default {
  components: {
    pageBuilder () { return import('@/components/pageBuilder/list') }
  }
}
</script>
